
/**
 * In-memory cache. Will be providing indexedDb cache in a later stadium.
 * @author Tom Valk
 */
export class CacheStorage extends EventTarget {
  #app;
  #cache = {};

  /**
   * @param {QogniApp} app
   */
  constructor(app) {
    super();
    this.#app = app;
  }

  /**
   * @param {QogniApp} app
   * @returns {CacheStorage}
   */
  static factory(app) {
    return new this(app);
  }

  clear() {
    this.#cache = {};
  }

  /**
   * Get value of a cache item by key.
   *
   * @param key
   * @param def
   * @returns {*|undefined}
   */
  getValue(key, def = undefined) {
    return Object.prototype.hasOwnProperty.call(this.#cache, key) ? this.#cache[key] : def;
  }

  /**
   * Sets a value in the cache for the specified key.
   *
   * @param {string} key - The key for the value to be set.
   * @param {*} value - The value to be stored in the cache.
   * @return {CacheStorage}
   */
  setValue(key, value) {
    this.#cache[key] = value;
    return this;
  }
}
