import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";
import { removeFalsyValues } from "@qogni-technologies/design-system/src/shared/common";
import { config } from "../qogni-app-config"; 

export class MessagesDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getConversions(options) {
    return await this.#api.getData(
      `/conversations?${new URLSearchParams(options?.query ?? {})}`
    );
  }

  async getConversionById(conversationId) {
    return await this.#api.getData(`/conversations/${conversationId}`);
  }

  async getConversionMessages(conversationId, options) {
    options = removeFalsyValues(options);

    const query = {
      per_page: 100,
      ...(options && { ...options }),
    };
    return await this.#api.getData(
      `/conversations/${conversationId}/messages?${new URLSearchParams(query)}`
    );
  }

  async createNewConversation(participants) {
    try {
      return await this.#api.postData("/conversations", { participants });
    } catch (e) {
      if (e.response?.status === 409 && e.errorData?.data) {
        return {
          existing: true,
          ...e.errorData,
        };
      }
      throw e;
    }
  }

  async sendMessages(conversationId, content) {
    await this.#api.postData(`/conversations/${conversationId}/messages`, {
      content,
    });
  }

  async markReadBulk(conversationId, untilDate) {
    return await this.#api.postData(`/conversations/${conversationId}/read`, {
      until: untilDate.toISOString(),
    });
  }

  async deleteMessages(conversationId, messageId) {
    return await this.#api.deleteData(
      `/conversations/${conversationId}/messages/${messageId}`
    );
  }

  formatDateTime(date, last_message = null, withTime = true, rangeDays = 2) {
    const today = new Date();
    if (last_message && last_message.created_at)
      date = new Date(last_message.created_at);

    let extraOptions = {
      mode: "auto",
      languages: navigator.languages,
      rangeDays,
      timeStyle: undefined,
    };

    // If today, return only the time.
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      extraOptions = { ...extraOptions, timeOnly: true, rangeHours: 6 };
      return new Date(date).format(extraOptions);
    }

    if (!withTime) {
      extraOptions = { ...extraOptions, mode: "auto", timeStyle: undefined };
    }

    return new Date(date).format(extraOptions);
  }

  /**
   * Looks up the Qogni Coach user and either starts a new chat message, or an email.
   * @param {String} text Text to take as a question to the Qoach.
   */
  async askQoach(text) {
    const qoach = await this.getQoachUser();
    if (qoach) {
      const conversation = await this.createNewConversation([qoach.id]);

      if (conversation.data?.id) {
        app.goTo(`/messages/${conversation.data.id}`);
        setTimeout(() => {
          const messageInput = document.querySelector(
            "main x-form [name='message']"
          );
          if (messageInput) {
            messageInput.value = text;
            messageInput.focus();
          }
        }, 500);
        return;
      }
      
    } 
      
    const mailBody = encodeURIComponent(
      `Hi coach,

I have a question for you: 

${text}

Can you answer this?

Thanks,
${app.session.user.firstname}
`
    );

    window.open(
      `mailto:${config.qoachEmail}?subject=Question&body=${mailBody}`
    );
  
  }

  /**
   * Looks up the Qogni Qoach account.
   * @returns the user with the Qogni Qoach email address
   */
  async getQoachUser() {
    const url = `/users?${new URLSearchParams({
      include_empty_names: true,
      filter: config.qoachEmail,
      per_page: 1,
    })}`;

    const result = await this.#api.getData(url);
    return result.data.pop();
  }
}
