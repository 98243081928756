import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { MessagesDomain } from "../../domain/messages-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { createRef, ref } from "lit/directives/ref.js";
import { AccountDomain } from "../../domain/account-domain";
import "./thread";

export class PageMessages extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;
  #accountDomain;
  #modalRef = createRef();
  #infinityListElement = createRef();

  #onVisibilityChangeBound;

  constructor() {
    super();
    this.#domain = new MessagesDomain();
    this.#accountDomain = new AccountDomain();
    this.conversations = undefined;
    this.pagination = undefined;
    this.userList = undefined;
    this.loading = true;

    this.#onVisibilityChangeBound = this.#onVisibilityChange.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();

    app.notification.pollInterval = 2000;
    app.notification.addEventListener(
      "statechange",
      this.#notificationChange.bind(this)
    );
    app.notification.pollAction();

    document.addEventListener(
      "visibilitychange",
      this.#onVisibilityChangeBound
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    app.notification.pollInterval = 10000;
    app.notification.removeEventListener(
      "statechange",
      this.#notificationChange
    );

    document.removeEventListener(
      "visibilitychange",
      this.#onVisibilityChangeBound
    );
  }

  async #notificationChange(e) {
    if (e.detail.oldValue === undefined) return;

    // Force reload of conversations list.
    await this.#getConversations(true);

    if (this.#infinityListElement.value)
      this.#infinityListElement.value.addItems(this.conversations, true);
  }

  static get properties() {
    return {
      conversations: { type: Array },
      pagination: { type: Object },
      userList: { type: Array },
      loading: { type: Boolean },
    };
  }

  async #getConversations(refresh = false) {
    const task = async () => {
      try {
        const response = await this.#domain.getConversions({
          query: { refresh: refresh ? 1 : 0 },
        });
        this.conversations = response.data;
        this.pagination = response.pagination;
        this.loading = false;
      } catch (err) {
        app.addToastMessage(`Conversations: ${err}`, { type: "error" });
      }
      this.requestUpdate();
    };

    await Task.run(task);
  }

  async #createAction() {
    this.#modalRef.value.showModal();
    this.#getUsers();
  }

  async #createSelectUser(e) {
    const task = async () => {
      const result = await this.#domain.createNewConversation([e.detail.id]);

      if (result.existing)
        app.addToastMessage(
          "You already have an open conversation with this user. Opening conversation..."
        );

      // Redirect to conversation.
      window.location.replace(`/messages/${result.data.id}`);
    };

    await Task.run(task, {
      ghost: document.documentElement,
      description: "Creating new conversation...",
      timeout: 10000,
    });
  }

  async #getUsers() {
    const task = async () => {
      try {
        const response = await this.#accountDomain.getConnections({
          per_page: 250,
        });
        this.userList = response.data;
      } catch (err) {
        app.addToastMessage(`Connections: ${err}`, { type: "error" });
      }
    };

    await Task.run(task, {
      ghost: this.#modalRef.value,
      description: "Loading users.",
    });
  }

  renderPage() {
    return html`
      <section class="hero">
        <flex-container class="justify-content-space-between">
          <flex-item>
            <a class="button green small" @click=${this.#createAction}>
              <svg-icon icon="plus" size="14px"></svg-icon>
              New
            </a>
          </flex-item>
        </flex-container>
      </section>

      ${this.loading
        ? html`
            <app-shimmer class="title"></app-shimmer>
            <app-shimmer></app-shimmer>
            <app-shimmer class="mb"></app-shimmer>

            <app-shimmer class="title"></app-shimmer>
            <app-shimmer></app-shimmer>
            <app-shimmer class="mb"></app-shimmer>

            <app-shimmer class="title"></app-shimmer>
            <app-shimmer></app-shimmer>
            <app-shimmer class="mb"></app-shimmer>
          `
        : nothing}
      ${this.conversations === null || this.conversations?.length === 0
        ? html`
            There are no conversations yet! Go ahead and talk to specialists,
            connections and Qogni employees.
          `
        : nothing}

      <infinite-list
        ${ref(this.#infinityListElement)}
        .renderItem=${this.#renderConversation.bind(this)}
        @scroll-end=${this.#onCnvScrollEnd}
      ></infinite-list>

      <modal-dialog ${ref(this.#modalRef)}>
        <span slot="title">Create conversation</span>
        <svg-icon
          slot="title-action"
          icon="close"
          size="16px"
          dismiss
        ></svg-icon>
        <p>Select a contact you want to start chatting with.</p>

        <form>
          <label>
            <span data-label="">Find connections</span>
            <connections-autocomplete
              @result-selected=${this.#createSelectUser}
              .userList=${this.userList}
            ></connections-autocomplete>
          </label>
        </form>
        <button
          slot="action"
          type="submit"
          class="mb-tiny tiny outline beige"
          dismiss=""
        >
          Cancel
        </button>
      </modal-dialog>
    `;
  }

  #renderConversation(conv) {
    if (!conv.last_message) return nothing;
    const otherUser = conv.participants.filter(
      (p) => p.id !== app.session.user.id
    )[0];
    const lastMsg =
      conv.last_message.content.length <= 150
        ? conv.last_message.content
        : conv.last_message.content.slice(0, 150 - 3) + "...";

    return html`
      <message-card>
        <a href="/messages/${conv.id}">
          <section class="card message">
            <figure>
              ${otherUser
                ? html`<profile-picture
                    name="${otherUser?.firstname} ${otherUser?.lastname}"
                    img="${otherUser?.profile_img_url}"
                    uuid=${otherUser?.id}
                    link=${`/profile/${otherUser?.id}`}
                    size="50px"
                  >
                  </profile-picture>`
                : html`<profile-picture
                    name="Deleted user"
                    img="/assets/img/profile-picture.webp"
                    size="50px"
                  >
                  </profile-picture>`}
              ${conv.unread_count > 0
                ? html` <span class="count">${conv.unread_count}</span> `
                : nothing}
            </figure>

            <div class="info">
              <div class="title">
                ${!otherUser
                  ? "Deleted user"
                  : `${otherUser?.firstname} ${otherUser?.lastname}`}
              </div>
              <p>
                ${conv.last_message?.user_id
                  ? html`
                      ${conv.last_message.user_id === app.session.user.id
                        ? html`Me: `
                        : html`${otherUser?.firstname}: `}
                    `
                  : nothing}
                ${lastMsg}
              </p>
            </div>

            <div class="controls">
              <svg-icon icon="meatballs" size="30px"></svg-icon>
              <div class="time">
                <time
                  datetime="${conv?.last_message?.created_at}"
                  data-update-interval="30000"
                ></time>
              </div>
            </div>
          </section>
        </a>
      </message-card>
    `;
  }

  lastMessageDateString(conversation) {
    let date = new Date(conversation.created_at);
    return this.#domain.formatDateTime(date, conversation.last_message, false);
  }

  async #refreshCnv() {
    await this.#getConversations(true);
    if (this.#infinityListElement.value)
      this.#infinityListElement.value.addItems(this.conversations, true);
  }

  async #onCnvScrollEnd() {
    if (this.conversations?.length && !this.pagination?.next_cursor) return;
    await this.#getUsers();
    await this.#getConversations();
    if (this.#infinityListElement.value)
      this.#infinityListElement.value.addItems(this.conversations);
  }

  async #onVisibilityChange() {
    if (!document.hidden) {
      await this.#refreshCnv();
    }
  }

  async updated(changeProps) {
    await super.updated(changeProps);

    if (changeProps.has("conversations")) {
      setTimeout(() => {
        app.enhancers.run(this);
      });
    }
  }
}
