import { FlowPage, UI as baseUI } from "../shared/flow-page";
import { AccountDomain } from "../domain/account-domain";

const placeholders = {
  list: [
    "Tell me all about magnesium",
    "Can you provide a healthy recipe that's low in calories and high in nutritional value?",
    "What's the best way to keep fit after 60?",
    "I need to know what has changed over the last couple of weeks that gives me regular headaches",
    "What is the scientifically proven benefit of Ashwagandha?",
    "What food has a high protein value?",
    "What supplements could I take to improve my sleep, so I don't have to resort to sleeping pills?",
    "What can I do during winter to keep my vitamin D level up?",
    "What food matches my current health profile and intentions?",
  ],
  index: 0,
};

const UI = {
  ...baseUI,
  searchInput: {
    ...baseUI.longtext,
    placeholder: placeholders.list[0],
    minlength: 4,
    title: "Ask Anything ¹",
    class: "speech-enabled",
    rows: 8,
    footnotes: {
      "¹": "You'e talking to Qoach, your own, fully personal Qogni Coach - who can keep a secret 😉",
    },
  },
};

export class PageAskQoach extends FlowPage {
  async flowStart(wf) {
    const question = await wf.ask("What do you want to know?", UI.searchInput);

    const aiResult = await this.getAnswer(wf, question);

    await this.showHtml(
      wf,
      `<p>
      <b>You</b>: 
      <i>${question}</i>
      </p>
      <div>
      <b>Qoach</b>:${aiResult}
      </div>`,
      ""
    );

    this.closeFlow();
  }

  get icon() {
    return "search";
  }

  async getAnswer(wf, question) {
    return await wf.askAI(
      `
        You're an intelligent search engine, and you can answer anything from personal health issues to diets and allergies!

        This is what the user is looking for: 

      ${question}

      -------

      Please analyze this query, paraphrase it concisely and informally, 
      and provide an answer, addressing the user directly (${
        app.session.user.firstname
      }).
      
      By the way, just in case: this is what we know about ${
        app.session.user.firstname
      }: ${this.getUserText()}

      `,
      {
        format: "string",
        html: true,
        role: "qoach",
        temperature: 0.5,
        includes: ["user_journey", "app_content"],
      }
    );
  }

  getUserText() {
    const o = AccountDomain.singleton.getHealthDetails();

    return `
User: ${o.fullName}
Diet: ${o.diet}
Age: ${o.age}

Allergies & Intolerances: ${
      !o.allergies?.length ? "none" : o.allergies.join(", ")
    }

Interest topics:
- ${app.session.user.interest_topics?.map((x) => x.name).join("\n -")}

Health intention:
${app.session.user.health_intention}
`;
  }

  firstUpdated() {
    super.firstUpdated();

    this.rotatePlaceholders();
  }

  rotatePlaceholders() {
    const txt = this.querySelector("textarea[name='step']");
    let waitMs = 2000;

    if (txt) {
      txt.placeholder = placeholders.list[placeholders.index];
      waitMs = 1000 + txt.placeholder.split(" ").length * 0.1 * 1000;

      placeholders.index++;
      if (placeholders.index === placeholders.list.length)
        placeholders.index = 0;
    }

    setTimeout(this.rotatePlaceholders.bind(this), waitMs);
  }
}
