import { html } from "lit";
import { version as AppVersion } from "../qogni-app-config";
import { ApiRequest } from "../shared/APIRequest";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { FlowPage, UI as baseUI } from "../shared/flow-page";

const UI = {
  ...baseUI,
  feedback: {
    ...baseUI.longtext,
    placeholder: "Enter your feedback...",
    minlength: 20,
    class: "speech-enabled",
    rows: 8,
  },
};

export class PageFeedback extends FlowPage {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  get icon(){
    return "like"
  }

  async flowStart(wf) {
    const feedbackText = await wf.ask(
      "Qogni needs your input to improve! Please tell us anything you like, dislike, or want to report an issue with.",
      UI.feedback
    );

    const aiResult = await wf.askAI(`
        You are a friendly helpdesk manager, here to help and stay positive!

        The user has submitted this feedback about his/her experience with our Qogni app: 

      ${feedbackText}

      -------

      Please analyze the feedback and paraphrase the issue concisely and informally, 
      addressing the user directly ${app.session.user.firstname}.

      Tell the user the feedback will be handled with commitment, 
      because all feedback is good feedback, and improving the app is easier 
      with user feedback.

      return plain text, not markdown.
      `);

    setTimeout(() => {
      this.submitFeedBack(feedbackText);
    }, 1);

    await this.showHtml(wf, html` ${aiResult} `, "We heard you!");

    this.closeFlow();
  }

  async showHtml(wf, htmlToRender, title) {
    await wf.ui(null, {
      renderInput: () => htmlToRender,
      title: title,
    });
  }

  submitFeedBack(text) {
    this.createFeedback(text).then(() => {
      app.addToastMessage("Feedback has been submitted successfully");
    });
  }

  async createFeedback(msg) {
    const payload = {
      comment: msg,
      user_agent: navigator.userAgent,
      application: "pwa",
      version: AppVersion,
      page: window.location.href + "",
      browser: JSON.stringify(getBrowser()),
    };

    return Task.run(async () => this.#api.postData("/feedback", payload), {
      description: "Posting feedback",
    });

    function getBrowser() {
      const b = {
        name: "Unknown",
        version: "Unknown",
        agent: navigator.userAgent,
      };

      const getVersion = (s) => {
        const match = s.split("/")[1];
        return match.split(".")[0];
      };

      switch (true) {
        case b.agent.indexOf("Edg/") > -1:
          b.name = "Edge";
          b.id = "Edg";
          break;
        case b.agent.indexOf("Opr/") > -1 && !!window.opr:
          b.name = "Opera";
          break;
        case b.agent.indexOf("Chrome/") > -1 && !!window.chrome:
          b.name = "Chrome";
          b.id = "Chrome/";
          break;
        case b.agent.indexOf("Trident/") > -1:
          b.name = "Internet Explorer";
          break;
        case b.agent.indexOf("Firefox/") > -1:
          b.name = "Firefox";
          break;
        case b.agent.indexOf("Safari/") > -1:
          b.name = "Safari";
          break;
      }
      const i = b.agent.indexOf(b.id);
      const s = b.agent.substring(i);
      b.version = getVersion(s);
      return b;
    }
  }
}
