import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";

export class ArticleDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getArticle(articleId) {
    let result;

    try {
      result = await this.#api.getData(`/articles/${articleId}`);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 403:
          app.addToastMessage("Not allowed", {
            type: "error",
          });
          return false;
        case 404:
          app.addToastMessage("Article not found by UUID", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data;
  }
}
