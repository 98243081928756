// eslint-disable-next-line no-undef
import { ProgramDomain } from "../../domain/program-domain";

const env = require("env");
import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { QuestionnaireDomain } from "../../domain/questionnaire-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { askWizard } from "@qogni-technologies/design-system/src/components/base/modal-dialog";
import { Converter } from "showdown";

export class PageProgramActivation extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #questionnaireDomain;
  #pollingInterval;
  #tries;
  availableProgram;

  constructor() {
    super();
    this.stripe = null;
    this.loading = true;
    this.availableProgram = null;
    this.incompatible = false;
    this.orderPending = false;
    this.orderStatus = null;
    this.#domain = new ProgramDomain();
    this.#questionnaireDomain = new QuestionnaireDomain();
    this.#tries = 0;
  }

  static properties = {
    loading: { type: Boolean },
    latest: { type: Object },
    incompatible: { type: Boolean },
    orderPending: { type: String },
    orderStatus: { type: Object },
  };

  connectedCallback() {
    super.connectedCallback();

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("state") === "complete" && urlParams.has("order")) {
      // Start poller to get status.
      this.orderPending = urlParams.get("order");
      this.#pollingInterval = setInterval(() => {
        this.#orderPolling();
      }, 3000);
      this.#orderPolling();
    }

    this.#loadResults();
    this.#loadActiveProgram();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.#pollingInterval) clearInterval(this.#pollingInterval);
  }

  async #orderPolling() {
    if (!this.orderPending) return;
    if (this.#tries > 25) {
      app.addToastMessage("We cannot complete your order right now, please contact us on info@qogni.com", { type: "error" });
      return;
    }

    const response = await this.#domain.getOrder(this.orderPending, {
      query: { polling: true },
    });
    this.orderStatus = response.data;
    if (this.orderStatus.status === "pending") {
      // Redirect to program page.
      clearInterval(this.#pollingInterval);
      window.location.replace("/program");
    }

    this.#tries++;
  }

  async #loadResults() {
    return Task.run(async () => {
      const response = await this.#questionnaireDomain.getLatest();
      this.latest = response.data;
      this.availableProgram = response.data?.braincheck?.available_program;
      this.loading = false;
    });
  }

  async #loadActiveProgram() {
    return Task.run(async () => {
      try {
        await this.#domain.getRunningProgram();
        await app.session.refreshUser();
        window.location.replace("/program");
      } catch (e) {
        if (e.response?.status === 403) {
          // Waiting for activation.
          await app.session.refreshUser();
          window.location.replace("/program");
        }
      }
      this.loading = false;
    });
  }

  async #order(e) {
    e.preventDefault();
    const answer = await this.#orderConfirmation();
    if (!answer) {
      this.incompatible = true;
      return;
    }

    // Create order and redirect to checkout page.
    await Task.run(async () => {
      const response = await this.#domain.createProgramOrder(
        this.availableProgram.id,
        {
          pregnant: false,
          medicine_use: false,
        }
      );
      window.location.href = response.data.stripe_url;
    });
  }

  async #orderConfirmation() {
    let steps = [
      { title: 'Confirmation of order', message: 'Are you using any medications?'},
    ];

    if(app.session.user?.sexe === 2) {
      steps.push({ title: 'Confirmation of order', message: 'Are you pregnant?'});
      steps.push({ title: 'Confirmation of order', message: 'Are you breastfeeding?'});
    }

    return await askWizard(steps);
  }

  renderPendingOrder() {
    if (this.orderStatus?.status === "cancelled")
      return this.renderOrderFailed();
    return html`
      <section class="hero center">
        <h1>Order status</h1>
        <p>Your order is being processed... Please wait a bit...</p>
      </section>

      <app-shimmer class="title"></app-shimmer>
      <app-shimmer class="tiny"></app-shimmer>
      <app-shimmer class="mb"></app-shimmer>
    `;
  }

  renderOrderFailed() {
    return html`
      <section class="hero center">
        <h1>Payment cancelled or failed</h1>
        <p>
          Your order has been cancelled or the payment failed. Please return to
          the program overview to try again.
        </p>

        <a href="/program" class="button">Program Overview</a>
      </section>
    `;
  }

  renderLoading() {
    return html`
      <app-shimmer class="title"></app-shimmer>
      <app-shimmer class="tiny"></app-shimmer>
      <app-shimmer class="mb"></app-shimmer>

      <app-shimmer class="title"></app-shimmer>
      <app-shimmer></app-shimmer>
      <app-shimmer></app-shimmer>
      <app-shimmer class="mb"></app-shimmer>

      <app-shimmer class="title tiny"></app-shimmer>
      <app-shimmer class="image"></app-shimmer>
      <app-shimmer></app-shimmer>
      <app-shimmer></app-shimmer>
    `;
  }

  renderIncompatible() {
    return html`
      <section class="hero center hide-full-width">
        <h1>Your personal program</h1>
      </section>

      <section class="card light-blue">
        <p>
          Based on your responses, we cannot start a program for you at this time.
          The use of medications, pregnancy, or breastfeeding may lead to interactions with our supplements.
          At Qogni, your health and safety are our top priorities.
          A health professional will contact you soon to discuss the next steps and possible alternatives.
          We appreciate your patience and are committed to providing you with the best possible support.
        </p>
      </section>
    `;
  }

  renderNoBraincheck() {
    return html`
      <section class="hero center hide-full-width">
        <h1>Your personal program</h1>
      </section>

      <section class="card">
        <h3>Finish your BrainCheck to access your program!</h3>
        <p>Complete your BrainCheck to unlock your personalized program.</p>
        <a href="/braincheck" class="button wide">Go to the BrainCheck</a>
      </section>
    `;
  }

  renderNoFunding() {
    return html`
      <section class="hero center hide-full-width">
        <h1>Your personal program</h1>
      </section>

      <section class="card">
        <h3>As soon as your organization has a budget available, you can order your program through Qogni. We will keep you informed!</h3>
      </section>
    `;
  }

  render() {
    if (this.orderPending) return this.renderPendingOrder();
    if (this.loading) return this.renderLoading();
    if (this.incompatible) return this.renderIncompatible();
    if (!this.availableProgram?.id) return this.renderNoBraincheck();
    if (!this.availableProgram?.can_be_ordered) return this.renderNoFunding();

    const price = this.availableProgram.product?.price ?? 0;
    const currencyCode = this.availableProgram.product?.currency?.code ?? "EUR";
    const description =
      this.availableProgram.product?.description ??
      this.availableProgram.description;
    return html`
      <section class="hero center">
        <h1>Activate your program</h1>
      </section>

      <section class="card red">
        <h2>${this.availableProgram.name}</h2>

        <p>${unsafeHTML(new Converter().makeHtml(description))}</p>

        <button
          type="button"
          class="wide white"
          @click=${this.#order.bind(this)}
        >
          Activate this program:
          ${new Intl.NumberFormat(navigator.languages, {
            style: "currency",
            currency: currencyCode,
          }).format(price)}
        </button>
      </section>
    `;
  }
}
