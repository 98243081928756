import {
  askConfirm,
  showAlert,
} from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { AccountDomain } from "../../domain/account-domain";
import { PostDomain } from "../../domain/post-domain";
import { TimelineDomain } from "../../domain/timeline-domain";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../common";
import { ArticleDomain } from "../../domain/article-domain";

export class ArticleEntry extends LitElement {
  createRenderRoot() {
    return this;
  }

  #domain;
  #articleDomain;
  #nextCommentCursor = null;
  #commentRichEditorRef = createRef();

  static get properties() {
    return {
      index: { type: Number },
      articleId: { type: String },
      for: { type: String },

      // Timeline uses
      entry: { type: Object },

      // Article
      article: { type: Object },

      selfFetch: { type: Boolean, attribute: "self-fetch" },
    };
  }

  constructor() {
    super();
    this.#domain = new TimelineDomain();
    this.#articleDomain = new ArticleDomain();
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.selfFetch && this.articleId) {
      await this.#getArticle();
    }

    if (this.entry && this.entry.article) {
      this.article = this.entry.article;
    }
  }

  async #getArticle() {
    const task = async () => {
      this.article = await this.#articleDomain.getArticle(this.articleId);
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading article...",
    });
  }

  updated(changeProps) {
    if (
      changeProps.has("entry") ||
      changeProps.has("article")
    ) {
      setTimeout(() => {
        app.enhancers.run(this);
      });
    }
  }

  async #onShare(article) {
    const task = async () => {
      const { id, name } = article;
      const { absoluteUrl } = config;
      const link = `${absoluteUrl}/articles/${id}`;

      await nativeWebShare({ title: name, text: link });
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }

  render() {
    if (!this.article) {
      return html`
        <section class="card">
          <app-shimmer class="title"></app-shimmer>
          <app-shimmer class="tiny"></app-shimmer>
          <app-shimmer class="mb"></app-shimmer>
          <app-shimmer class="image"></app-shimmer>
        </section>
      `;
    }

    return this.renderArticle(this.article);
  }

  renderArticle(article) {
    const { name, content } = article;
    return html`
      <h1>${name}</h1>

      <section class="card">
        <translatable-content current-language="en">
          ${unsafeHTML(new Converter().makeHtml(content))}
        </translatable-content>
      </section>
    `;
  }
}

customElements.define("article-entry", ArticleEntry);
