import { FlowPage, UI as baseUI } from "../shared/flow-page";

const UI = {
  ...baseUI,
};

export class PageESS extends FlowPage {
  async flowStart(wf) {
    await this.showHtml(wf, "Coming soon!", "Employee Satisfaction Survey");

    this.closeFlow();
  }
}
