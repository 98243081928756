import { LitElement } from "lit";
import { loadScript } from "../common";

customElements.define(
  "reward-animation",
  class TopBar extends LitElement {
    connectedCallback() {
      this.initConfetti();
    }

    initConfetti() {
      if (typeof window.JSConfetti === "undefined")
        loadScript("/assets/js/lib/js-confetti.browser.js", this.confetti);
      else this.confetti();
    }

    confetti() {
      // eslint-disable-next-line no-undef
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti({
        emojis: ["🪙", "🎊"],
      });
    }
  }
);
