// home.js

import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  WidgetEnabledPWAPage,
} from "../shared/pwa-page";
import { repeat } from "lit/directives/repeat.js";

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export class PageHome extends OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
) {
  static get properties() {
    return {
      ...super.properties,
      tip: {
        type: Number,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.widget) {
      this.tips = [
        html`<p>
          You can send us feedback anytime, using the
          <a href="/feedback">feedback page</a>!
        </p>`,
        html`<p>
          You can set your diet and allergies using <a href="/onboarding/health">this flow</a>.
        </p>`,

      ];

      this.tip = getRandomInt(0, this.tips.length);
    }
  }

  renderPage() {
    const widgets = app.config.widgets.sort((w1, w2) => {
      const s1 = w1.run?.prototype?.getWidgetSettings
        ? (new w1.run()).getWidgetSettings()
        : { priority: 0 };
      const s2 = w2.run?.prototype?.getWidgetSettings
        ? (new w2.run()).getWidgetSettings()
        : { priority: 0 };
      return s1?.priority > s2.priority ? -1 : 1;
    });

    return html`

      <widget-container class="masonry">
        ${repeat(widgets, (item) => {
          const settings  = item?.run?.prototype?.getWidgetSettings
            ? (new item.run()).getWidgetSettings()
            : { priority: 0 };
          if (settings.hidden) return nothing;
          return html`${item.renderWidget(settings)}`
        })}
      </widget-container>`;
  }

  getWidgetSettings() {
    return {
      ...super.getWidgetSettings(),
      priority: 898,
      hidden: true,
      title: 'Did you know?',
      cta: {
        icon: 'info',
      }
    };
  }

  renderWidget() {
    return html`
      <section class="card .flex">
        <div class="tip">${this.tips[this.tip]}</div>
      </section> `;
  }
}
