import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AuthenticatedMixin, WidgetEnabledPWAPage } from "../shared/pwa-page";

const data = {
  new: [
    {
      title: "Better results",
      description: "You now have a better overview over your results.",
    },
    {
      title: "Edit comment",
      description: "You can now edit your comments.",
    },
    {
      title: "Qogni Groups",
      description: "Introducing groups for community building.",
    },
  ],
  cooking: [
    {
      title: "Translations",
      description:
        "You will see our application being translated part by part in several languages.",
    },
  ],
};

export class PageWhatsNew extends AuthenticatedMixin(WidgetEnabledPWAPage) {
  renderPage() {
    return this.renderItems();
  }

  getWidgetSettings() {
    return {
      ...(super.getWidgetSettings() ?? {}),
      priority: 900,
      title: "New & Upcoming",
      cta: {
        href: "/whats-new",
      },
    };
  }

  renderWidget() {
    return html`
      <section class="card">
        <h3>New Qogni Features</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => {
            return html`<li>${item.title}</li>`;
          })}
        </ul>
      </section>
    `;
  }

  get title(){
    return "What's New"
  }

  renderItems(compact) {
    return html`
      
      <section class="card">
        <h3>New Qogni Features</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => this.renderItem(item, compact))}
        </ul>
      </section>

      <section class="card">
        <h3>
          ${compact
            ? html`Coming in the next releases`
            : html`Check out what's coming in the next releases`}
        </h3>
        <ul>
          ${repeat(data.cooking, (item) => this.renderItem(item, compact))}
        </ul>
      </section>
    `;
  }

  renderItem(item, compact) {
    return html`<li>
      ${item.title} ${compact ? "" : html`<small>${item.description}</small>`}
    </li>`;
  }
}
