import {isIOS, parseHTML} from "@qogni-technologies/design-system/src/shared/common";
import {config} from "../qogni-app-config";

export class ServiceWorkerManager extends EventTarget {
  #serviceWorker;
  #newWorker;
  #refreshing = false;
  #deferredPrompt;
  #installBlock;

  constructor() {
    super();

    this.#registerServiceWorker().then(() => {
      this.#setupInstallPrompt();
    });
  }

  async #registerServiceWorker() {
    if (typeof navigator.serviceWorker === "undefined") return;
    if (!config.serviceWorker.enabled) {
      console.warn("Service worker disabled in config")
      return;
    }

    const me = this;
    // make the whole serviceworker process into a promise so later on we can
    // listen to it and in case new content is available a toast will be shown
    me.isUpdateAvailable = new Promise(function (resolve) {
      navigator.serviceWorker
        .register("/service-worker.js", {
          scope: "/",
        })
        .then((reg) => {
          me.#serviceWorker = reg;
          // console.log("ServiceWorker registered for", me.#serviceWorker.scope);

          reg.addEventListener("updatefound", (e) => {
            console.warn("SW UPDATE FOUND", e);

            const installingWorker = reg.installing;
            me.#newWorker = installingWorker;
            installingWorker.onstatechange = () => {
              switch (installingWorker.state) {
                case "installed":
                  if (navigator.serviceWorker.controller) {
                    // new update available
                    resolve(true);
                  } else {
                    // no update available
                    resolve(false);
                  }
                  break;
              }
            };
          });
        });
    });

    me.isUpdateAvailable.then((isAvailable) => {
      if (isAvailable) {
        this.#showUpdatePrompt();

        const updateNotif = document.getElementById("sw-update-available");
        updateNotif.classList.add("active");
      }
    });

    self.addEventListener("message", function (event) {
      switch (event.data.type) {
        case "UPDATE_AVAILABLE":
          document.getElementById("sw-update-available").classList.add("active");
          break;
        case "reloadPage":
          window.location.reload(true);
          break;
        default:
          switch (event.data.action) {
            case "installed":
              localStorage.setItem("installed-version", event.data.version);

              this.dispatchEvent(
                new CustomEvent("new-version", {
                  detail: {
                    version: event.data.version,
                  },
                })
              );
              break;
          }
          break;
      }
    });
  }

  #setupInstallPrompt() {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      this.#addInstallBlock();
      // Save the event to use it later
      window.promptEvent = event;
    });

    navigator.serviceWorker.addEventListener("message", (e) => {
      if (e.data && e.data.command === "deferredPrompt")
        this.#installBlock.classList.add("show");
    });
  }

  #addInstallBlock() {
    if (localStorage.getItem("install-cancelled") === "1") return;

    this.#installBlock = this.#getInstallHTML(
      "Qogni - Your Health Buddy!",
      "If you use Qogni a lot, install it as an App, wich will allow you to get a home screen icon and a separate, focused window!",
      "/assets/img/logo2.svg"
    );

    this.#installBlock
      .querySelector("#BlockInstallButton")
      ?.addEventListener("click", this.#addToHomeScreen.bind(this));
    this.#installBlock
      .querySelector(".close")
      .addEventListener("click", this.#cancelInstall.bind(this));

    document.body.appendChild(this.#installBlock);

    setTimeout(() => {
      this.#installBlock.classList.add("active");
    }, 1000);
  }

  #getInstallHTML(title, description, logoImage) {
    return parseHTML(/*html*/ `
    <div class="sw-install hidden modeless" id="BlockInstall">
      <div class="inner">
        <div class="close" id="BlockInstallClose">
            <span>
              <svg-icon icon="close"></svg-icon>
            </span>
        </div>
        <div class="logo">
            <img src="${logoImage}" height="50px" />
        </div>
        <section class="name card">
            <h3 class="title">${title}</h3>
            <small class="description">${description}</small>
        </section>
        <nav class="cta">
          ${
      isIOS()
        ? "Tab on <svg-icon icon='share' size='24px' color='var(--color-accent-300)'></svg-icon> and <br> Click on <b>Add to Home Screen</b>"
        : '<button id="BlockInstallButton" class="primary">Install</button>'
    }
        </nav>
      </div>
    </div>`)[0];
  }

  #cancelInstall() {
    localStorage.setItem("install-cancelled", "1");
    this.#installBlock.remove();
  }

  #addToHomeScreen() {
    if (! window?.promptEvent) return;

    // Install prompt
    window.promptEvent.prompt();

    // I added a Google Analytics Event so we can know how many installs we have
    window.promptEvent.userChoice.then(function (choiceResult) {
      if (choiceResult.outcome === "accepted") {
        // Google Analytics:
        // gtag("event", "Installed PWA", {
        //     event_category: "PWA",
        //     value: 1,
        // });
      } else {
        // Do nothing
      }
      window.promptEvent = null;
    });
  }

  #showUpdatePrompt() {
    const swNotif = parseHTML(
      /*html*/`
        <div title="Click to install new App version" id="sw-update-available" class="modeless active">
          <section class="card">
            <h3>New version available</h3>
            <small>
              There's a new verson of Qogni available to install.
            </small>
            <nav>
              <a id="reload" class="button primary">
                <svg-icon icon="bell"></svg-icon>
                <span>Install new version</span>
              </a>
            </nav>
          </section>
        </div>`)[0];
    document.body.appendChild(swNotif);

    swNotif.addEventListener("click", () => {
      swNotif.classList.remove("active");
      if (this.#newWorker === undefined) return window.location.reload();

      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data.type === 'skipWaitingComplete') {
          window.location.reload(true);
        }
      });

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!this.#refreshing) {
          window.location.reload();
          this.#refreshing = true;
        }
      });

      this.#newWorker.postMessage({action: "skipWaiting"}); // install new version
      swNotif.classList.remove("active");
    });
  }
}
