import { html, nothing } from "lit";
import { ResultsBase } from "./results-base";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { repeat } from "lit/directives/repeat.js";
import { Converter } from "showdown";

export class PagePotential extends ResultsBase {
  constructor() {
    super();
    this.title = "Potential";
  }
  renderContent() {
    return html`
      ${this.#renderImprovementGraph()} ${this.#renderCognitiveImprovement()}
      ${this.renderProgram()}
    `;
  }

  #renderImprovementGraph() {
    return html`
      <section class="card dark-green">
        <h2>Current state vs. maximum potential</h2>

        <section class="card">
          ${this.improvementGraph
            ? html`<column-chart
                max="100"
                .data=${this.improvementGraph}
                type="beside"
              ></column-chart>`
            : nothing}
        </section>
      </section>
    `;
  }

  #renderCognitiveImprovement() {
    if (!this.improvementProfile) return nothing;
    return html`
      <section class="card">
        <h2>Areas of cognitive improvement</h2>

        <figure class="center mb-small">
          <img
            src="/assets/img/balance.svg"
            alt="Girl balancing"
            loading="lazy"
          />
        </figure>

        ${this.improvementProfile.subtext
          ? html`
              <translatable-content current-language="en">
                ${unsafeHTML(
                  new Converter().makeHtml(this.improvementProfile.subtext)
                )}
              </translatable-content>
            `
          : nothing}
        ${repeat(this.improvementProfile.texts, (text, idx) => {
          const colors = ["red", "yellow", "green", "blue"];
          const color = colors[idx % colors.length];
          const { name, value } = text;

          return html`
            <details class="${color}">
              <summary>${name}</summary>
              <!-- Content -->
              <section>
                <translatable-content current-language="en">
                  ${unsafeHTML(new Converter().makeHtml(value))}
                </translatable-content>
              </section>
            </details>
          `;
        })}

        <details class="blue">
          <summary>Qogni program</summary>
          <!-- Content -->
          <section>
            <p>
              <translatable-content current-language="en">
                ${this.improvementProfile.gaba &&
                this.improvementProfile.serotonin &&
                this.improvementProfile.dopamine &&
                this.improvementProfile.acetylcholine
                  ? html`
                      While you're already enjoying a state of mental and
                      emotional balance, Qogni offers a program to help maintain
                      and further enhance this harmony. If you're interested in
                      optimizing your cognitive and emotional prowess even more,
                      ensuring that you remain at your peak, you can delve into
                      our specialized program below.
                    `
                  : html`
                      If you recognize these signs, qogni has a tailored program
                      based on these outcomes. If you're interested in boosting
                      cognitive performance and getting on top of the world, you
                      can activate your personal program below.
                    `}
              </translatable-content>
            </p>
          </section>
        </details>
      </section>
    `;
  }
}
