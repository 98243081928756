import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { GroupDomain } from "../../../domain/group-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";

export class PageGroupsList extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #page = 1;
  #lastPage;
  #pagination;

  #fullList = [];

  static get properties() {
    return {
      list: { type: Array },
      _computedList: { type: Array },
    };
  }

  constructor() {
    super();
    this.#domain = new GroupDomain();
    this._computedList = [];
  }

  connectedCallback() {
    super.connectedCallback();

    this.#getList();
  }

  willUpdate(changeProps) {
    if (changeProps.has("list")) {
      this.#fullList = [...this.#fullList, ...this.list];
      const numOfChannels = this.#fullList.length;
      const { total } = this.#pagination;
      const numOfShimmer = total - numOfChannels;

      const shimmerList = Array.from({ length: numOfShimmer }, () => undefined);
      this._computedList = [...this.#fullList, ...shimmerList];
    }
  }

  async #getList() {
    return Task.run(async () => {
      const res = await this.#domain.list();
      if (!res.status) return;

      this.list = res?.data;
      this.#lastPage = res?.pagination?.last_page;
      this.#pagination = res?.pagination;
    });
  }

  async #refreshList() {
    this.#fullList = [];
    await this.#getList();
  }

  async #groupFollowToggle(group, target) {
    const task = async () => {
      const { id, is_member } = group;
      if (is_member) {
        await this.#domain.leave(id);
      } else {
        await this.#domain.join(id);
      }

      await this.#refreshList();
    };

    await Task.run(task, {
      ghost: target,
    });
  }

  async #onRangeChanged(e) {
    const { last } = e;
    if (last > this.#page * 25) {
      this.#page++;
      if (this.#page === 1 || this.#page <= this.#lastPage) {
        await this.#getList();
      }
    }
  }

  render() {
    return html`
      <div class="heading">
        <h1>Groups</h1>
        <a class="button tiny" href="/network/groups/new">
          <svg-icon icon="plus"></svg-icon>
          Create New
        </a>
      </div>

      ${this.#renderList()}
    `;
  }

  #renderList() {
    if (this._computedList.length === 0) {
      const shimmerList = Array.from({ length: 5 }, () => ({}));
      return html`
        ${repeat(
          shimmerList,
          () => html`<general-shimmer type="card"></general-shimmer>`
        )}
      `;
    }
    return html`
      <lit-virtualizer
        .items=${this._computedList}
        .renderItem=${this.#renderListItem.bind(this)}
        @rangeChanged=${this.#onRangeChanged}
      ></lit-virtualizer>
    `;
  }

  #renderListItem(group) {
    if (!group) return html`<general-shimmer type="card"></general-shimmer>`;
    const { type, is_member, requested_membership, is_admin, is_denied } =
      group;
    return html`
      <group-item>
        <svg-icon icon="people" size="24px"></svg-icon>
        <a href="/network/groups/${group.slug ?? group.id}/view">
          <h4>${group.name}</h4>
        </a>

        
        ${type === "closed"
          ? html`<badge-tag class="red">Private</badge-tag>`
          : nothing}
        ${is_admin ? html`<badge-tag class="blue">Admin</badge-tag>` : nothing}
        ${requested_membership
          ? html`<badge-tag class="align-end">Requested</badge-tag>`
          : nothing}
        ${!requested_membership && !is_denied && is_member && !is_admin
          ? html`<button
              type="button"
              class="button tiny outline align-end"
              @click=${() => this.#groupFollowToggle(group)}
            >
              Leave
            </button>`
          : html``}
        ${!requested_membership && !is_denied && !is_member
          ? html`<button
              type="button"
              class="button tiny outline align-end"
              @click=${() => this.#groupFollowToggle(group)}
            >
              ${type === "open" ? "Join" : "Request"}
            </button>`
          : nothing}
      </group-item>
    `;
  }
}
