import { LitElement, css, html } from "lit";

customElements.define(
  "type-writer",
  /**
   * Renders typed text
   */
  class TypeWriter extends LitElement {
    #items;

    constructor() {
      super();
      this.speed = 1
      this.period = 2000;
    }

    static get styles() {
      return [
        css`
          :host {
            display: block;
            height: 2rem;
          }

          .wrap:after {
            content: "|";
            color: currentColor;
            animation: blinker 1s infinite;
          }

          @keyframes blinker {
            50% {
              color: transparent;
            }
          }
        `,
      ];
    }

    get items() {
      return this.#items;
    }

    set items(value) {
      this.#items =
        typeof value === "string"
          ? value.split(",").map((i) => i.trim())
          : value;
    }

    connectedCallback() {
      super.connectedCallback();
      this.loopNum = 0;
      this.text = "";

      this.tick();
      this.isDeleting = false;
    }

    static get properties() {
      return {
        items: {},
        text: { type: String },
        speed: { type: Number, attribute: true },
        period: { type: Number },
        prefix: { type: String, default: "" },
        suffix: { type: String, default: "" },
      };
    }

    render() {
      return html`<span class="wrap">${this.prefix || ""} ${this.text}</span
        >${this.suffix || ""}`;
    }

    tick() {
      if (this.stopped) return;
      const count = this.items.length;
      var i = this.loopNum % count;

      var fullTxt = this.items[i];

      if (this.isDeleting) {
        this.text = fullTxt.substring(0, this.text.length - 1);
      } else {
        this.text = fullTxt.substring(0, this.text.length + 1);
      }

      var that = this;
      var delta = 120 - Math.random() * 115;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.text === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.text === "") {
        this.isDeleting = false;
        this.loopNum++;

        delta = 500;
      }

      if (this.text === this.items[count - 1])
        this.dispatchEvent(new CustomEvent("cycle"));

      this.tmr = setTimeout(function () {
        that.tick();
      }, delta / this.speed);
    }

    stop() {
      clearTimeout(this.tmr);
      this.tmr = null;
      this.stopped = true;
    }
  }
);
