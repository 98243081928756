import { ApiRequest } from "./APIRequest";
import { config } from "../qogni-app-config";

/**
 * Storage of session and verification of session in-memory.
 * @event received User is logged out, can be triggered due to JWT-session being expired or by action of logout (will be defined in event_source).
 * @event permission-granted User profile is updated.
 * @event permission-denied Authenticated
 * @author Tom Valk
 */
export class Translator extends EventTarget {
  #app;
  #api;

  /**
   * Translator Constructor, only to be used by the Qogni-App Class
   *
   * @internal
   * @param {QogniApp} app
   */
  constructor(app) {
    super();
    this.#app = app;
    this.#api = new ApiRequest(config.parentApiUrl);
  }

  /**
   * @param {QogniApp} app
   * @returns {Translator}
   */
  static factory(app) {
    return new this(app);
  }

  async translateText(text, options = {}) {
    if (! options.target_language) options.target_language = app.session.user?.language;

    const response = await this.#api.postData(`/ai/language_translate`, {
      content: text,
      to: options.target_language
    });

    return response.content;
  }
}
