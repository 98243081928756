import { LitElement, css, html } from "lit";

/**
 * Responsive slide-out panel Container Web Component
 * Supports coolean [open] attribute for opening and closing (using Web Standard best practices).
 * Used Shadow DOM and slotting for containment.
 */
customElements.define(
  "slide-panel",
  class BottomPanel extends LitElement {
    static get styles() {
      return [
        css`
          ::-webkit-scrollbar {
            width: 8px;
            height: 8px;

            &-button {
              width: 33px;
              height: 33px;
            }

            &-thumb {
              background: rgba(127, 127, 127, 0.2);
              border-radius: 10rem;
              cursor: pointer;
            }

            &-thumb:hover {
              background: var(--color-h-dimmed);
            }

            &-thumb:active {
              background: var(--color-h-dimmed);
            }

            &-track {
              background: rgba(0, 0, 0, 0.2);
              border: 22px none rgba(255, 255, 255, 0.2);
              border-radius: 50px;
            }

            &-track:hover {
              background: rgba(0, 0, 0, 0.2);
            }

            &-track:active {
              background: rgba(0, 0, 0, 0.2);
            }

            &-corner {
              background: transparent;
            }
          }
        `,
        css`
          :host {
            display: block;
            background-color: var(--color-primary-100, #111);
            border-radius: 1rem;
            box-shadow: 2px 2px 10px var(--color-shadow);
            padding: 1rem;
          }
          nav.close {
            z-index: 1;
            position: absolute;
            top: 10px;
            right: 10px;
          }

          nav.close a:hover, nav.close a:active{
            --icon-fill-color: var(--color-accent)
          }

          @media only screen and (max-width: 600px) {
            :host {
              position: fixed;
              right: -800px;
              top: var(--header-h);
              width: 100vw;
              bottom: 0;
              transition: all 0.3s ease-in-out;
            }
            :host([open]) {
              right: 0;
              transition: all 0.3s ease-in-out;
            }
          }

          @media only screen and (min-width: 601px) {
            :host {
              position: fixed;
              bottom: -600px;
              width: 600px;
              transition: bottom 0.3s ease-in-out;
              left: 50%;              
              transform: translateX(-50%);
            }
           

            #content {
              flex: 1;
              overflow-y: auto;
              max-height: 500px;
              
            }

            :host([open]) {
              bottom: 47px;
              transition: bottom 0.3s ease-in-out;
              left: 50%;
              transform: translateX(-50%);
              transition: all 0.3s ease-in-out;
            }
          }
        `,
      ];
    }

    static get properties() {
      return {
        open: {
          type: Boolean,
          attribute: true,
          reflect: true,
        },
      };
    }

    /** 
     * Shortcut to setting inner html and opening the panel.
     * @param {String|HTMLElement} html HTML to set in the panel.
     */
    show(html){
      if(typeof(html) === "string")
        this.innerHTML = html
      else if (html instanceof HTMLElement) {
        this.innerHTML = "";
        this.appendChild(html)
      }

      this.open=true
    }

    render() {
      return html`
        <nav class="close">
          <a
            href="#"
            @click=${(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.open = false;
            }}
            ><svg-icon icon="close" size="24px"></svg-icon
          ></a>
        </nav>
        <div id="content">
          <slot></slot>
        </div>
      `;
    }

    updated(changedProperties) {
      if (changedProperties.has("open")) {
        if (this.open) {
          setTimeout(() => {
            window.addEventListener("keydown", this.#escapeCheck.bind(this));

            document.addEventListener("mousedown", this.#escapeCheck.bind(this))


          }, 100);
        } else {
          window.removeEventListener("keydown", this.#escapeCheck.bind(this));
          document.removeEventListener("mousedown", this.#escapeCheck.bind(this))
        }
      }
    }

    #escapeCheck(e) {
      if (e instanceof KeyboardEvent ){
        if( e.key === "Escape") {
          this.open=false;
        }
      }
      else if (e instanceof MouseEvent){
        if( !this.contains(e.target)) {
          this.open=false;
        }
      }

    }
  }
);
