import {
  AuthenticatedMixin,
  FullscreenMixin,
  PWAPage,
} from "../shared/pwa-page";
import { QogniFlow } from "../shared/qogni-flow";
import { parseHTML } from "@qogni-technologies/design-system/src/shared/common";
import { SpeechTranscriber } from "../shared/speech-transcriber";
import { msg, str } from "@lit/localize";
import { QOGNI } from "./qogni";
import { html } from "lit";
export { UI } from "./qogni-flow";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

/**
 * Base flow page
 */
export class FlowPage extends FullscreenMixin(AuthenticatedMixin(PWAPage)) {
  connectedCallback() {
    super.connectedCallback();

    this.flow = new QogniFlow(this.options);
  }

  get options() {
    return {
      root: this.renderRoot,
      start: this.flowStart.bind(this),

      on: {
        init: (wf) => {
          wf.options.strings.back = "";

          wf.on("flow-ended", () => {
            setTimeout(() => {
              app.goTo(this.urlAfterFlow, {
                force: true,
              });
            }, 500);
          })
            .on("step-started", this.stepStarted.bind(this))
            .on("step-rendered", this.stepRendered.bind(this));
        },
      },
    };
  }

  render() {
    return html`
      ${this.renderWatermark()} ${this.flow.render()}

      <button @click=${this.closeFlow} class="tiny round outline red close-flow-ui">
        <svg-icon icon="close"></svg-icon>
      </button>
    `;
  }

  closeFlow() {
    this.flow.workflow.end();
    app.goTo("/", {
      force: true,
    });
  }

  async showHtml(wf, htmlToRender, title) {
    await wf.ui(null, {
      renderInput: () => {
        return unsafeHTML(
          `<div class="limited suppress-glossary">${htmlToRender}</div>`
        );
      },
      title: title,
    });
  }

  stepRendered() {
    this.tryEnableSpeech();
  }

  stepStarted() {
    // to be implemented by host
  }

  get urlAfterFlow() {
    return "/";
  }

  //#region flow

  // Flow Entrypoint
  // eslint-disable-next-line no-unused-vars
  async flowStart(wf) {
    throw new Error("Not implemented");
  }

  //#endregion

  //#region Local Flow actions

  //#endregion

  //#region helpers

  tryEnableSpeech() {
    this.querySelectorAll(".speech-enabled").forEach((txt) => {
      txt.style = "padding-right: 55px";
      txt.parentElement.style = "position: relative";
      const btn = parseHTML(
        /*html*/ `<button class="small outline round enable-speech" style="position: absolute; bottom: 20px; right: 11px"><svg-icon icon="microphone"></svg-icon></button>`
      )[0];

      txt.parentElement.appendChild(btn);
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        this.enableSpeech(txt);
      });
    });
  }

  enableSpeech(input) {
    const me = this;

    if (me.transcriber && me.transcriber.recording) {
      me.transcriber.stop();
      return;
    }

    input._placeholder = input.getAttribute("placeholder");

    if (me.transcriber) me.transcriber.stop();

    me.transcriber = new SpeechTranscriber(app.session.user.language, function (
      data
    ) {
      input.value = data;
      input.blur();
    })
      .on("start", () => {
        const lng =
          QOGNI.meta.languages[app.session.user.language] ?? "English";
        input.setAttribute(
          "placeholder",
          msg(str`Listening (${lng}), please speak...`)
        );
        me.classList.toggle("listening", true);
        input.addEventListener(
          "click",
          () => {
            if (me.transcriber) me.transcriber.stop();
            input.focus();
          },
          {
            once: true,
          }
        );
      })
      .on("stop", () => {
        input.setAttribute("placeholder", input._placeholder);
        me.classList.toggle("listening", false);
        me.transcriber = null;
      })
      .start();
  }

  //#endregion
}
