import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";

export class PagePrograms extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  render() {
    return html`
      <section class="card">
        <h2>Programs</h2>
        <p>
          Overview of all programs.
        </p>
        
      </section>
    `;
  }
}
