import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { RecipesDomain } from "../../domain/recipes-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PullToRefreshMixin,
  WidgetEnabledPWAPage,
} from "../../shared/pwa-page";

const BaseClass = PullToRefreshMixin(
  OnboardedMixin(AuthenticatedMixin(WidgetEnabledPWAPage))
);

export class PageRating extends BaseClass {
  #recipeDomain;
  #pagination;
  #ratingsCurrentPage = 1;
  #ratingsLastPage;
  #ratingRef = createRef();
  #ratingTextareaRef = createRef();
  #ratingFormRef = createRef();
  #infinityListElement = createRef();

  static get properties() {
    return {
      type: { type: String, routeOrigin: "pathname" },
      ratingId: { type: String, routeOrigin: "pathname" },

      content: { type: Object },
      ratings: { type: Object },
    };
  }

  constructor() {
    super();
    this.#recipeDomain = new RecipesDomain();
  }

  async firstUpdated() {
    await this.#fetch();
    if (Array.isArray(this.ratings) && this.ratings.length !== 0) {
      this.#infinityListElement.value.addItems(this.ratings);
    }
  }

  async #fetch() {
    const task = async () => {
      if (this.type === "recipes") {
        const contentRes = await this.#recipeDomain.getRecipe(this.ratingId);
        this.content = contentRes.data;

        const ratingsRes = await this.#recipeDomain.getRecipeReview(
          this.ratingId
        );
        this.ratings = ratingsRes?.data;
        this.#pagination = ratingsRes?.pagination;
        this.#ratingsCurrentPage = this.#pagination?.current_page;
        this.#ratingsLastPage = this.#pagination?.last_page;
      }
    };

    await Task.run(task);
  }

  async #onSubmitReview() {
    const task = async () => {
      if (this.type === "recipes") {
        const rating = this.#ratingRef.value.value;
        const comment = this.#ratingTextareaRef.value.value ?? "";

        if (comment && comment.length < 10) {
          this.#ratingTextareaRef.value.reportValidity();
          return;
        }
        const res = await this.#recipeDomain.createRecipeRating(
          this.ratingId,
          rating,
          comment
        );
        if (!res) return;
        await this.#refresh();
      }
    };

    await Task.run(task, {
      ghost: this.#ratingFormRef.value,
    });
  }

  async #refresh() {
    this.#ratingsCurrentPage = 1;
    await this.#fetch();
    this.#infinityListElement.value.addItems(this.ratings, true);
  }

  render() {
    return html`
      ${this.renderContent()} ${this.renderAddOwnReview()}
      ${this.renderRatings()}
    `;
  }

  renderContent() {
    if (!this.content) {
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
      `;
    }
    if (this.type === "recipes") {
      return html`<recipe-card
        .recipe=${this.content}
        showRating
      ></recipe-card>`;
    }
  }

  renderAddOwnReview() {
    return html`
      <h3>Add your own review</h3>
      <section class="card" ${ref(this.#ratingFormRef)}>
        ${this.content
          ? html`
              <h4>Add rating</h4>
              <range-rating value="5" ${ref(this.#ratingRef)}></range-rating>
              <form action="" method="post" class="material">
                <label>
                  Add comment
                  <textarea
                    name="rating"
                    rows="4"
                    minLength="10"
                    required
                    ${ref(this.#ratingTextareaRef)}
                  ></textarea>
                </label>
              </form>
              <button class="mb-tiny blue wide" @click=${this.#onSubmitReview}>
                Submit
              </button>
            `
          : html`
              <app-shimmer class="title"></app-shimmer>
              <app-shimmer class="image"></app-shimmer>
              <app-shimmer></app-shimmer>
            `}
      </section>
    `;
  }

  renderRatings() {
    if (!this.ratings) {
      return html`
        <section class="card">
          <app-shimmer class="title"></app-shimmer>
          <app-shimmer class="title"></app-shimmer>
          <app-shimmer class="tiny"></app-shimmer>
        </section>
      `;
    }

    if (Array.isArray(this.ratings) && this.ratings.length === 0) {
      return nothing;
    }

    return html`
      <h4>
        Reviews
        ${this.#pagination?.total ? html`(${this.#pagination.total})` : nothing}
      </h4>
      <infinite-list
        ${ref(this.#infinityListElement)}
        .renderItem=${this.#renderRatingListItem}
        @scroll-end=${this.#onScrollEnd.bind(this)}
      ></infinite-list>
    `;
  }

  #renderRatingListItem(item) {
    const { rating, user, comment } = item;

    return html`
      <section class="card">
        <div>
          <range-rating-view
            class="beige-bg"
            .value=${rating}
          ></range-rating-view>
          <label>
            by ${user?.firstname ?? "John"} ${user?.lastname ?? "Doe"}</label
          >
        </div>
        ${comment ? html`<div>${comment}</div>` : nothing}
      </section>
    `;
  }

  async #onScrollEnd() {
    this.#ratingsCurrentPage++;
    if (
      this.#ratingsCurrentPage === 1 ||
      this.#ratingsCurrentPage <= this.#ratingsLastPage
    ) {
      await this.#fetch();
      this.#infinityListElement.addItems(this.recipesList);
    }
  }
}
